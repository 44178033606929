// Mobile trigger
if (document.getElementById("js-notification-mobile") !== null) {
  var trigger = document.getElementById("js-notification-mobile");
  var container = document.getElementById("js-notification");
  trigger.addEventListener('click', function () {
    container.classList.toggle('is-active');
  });
}


// if (document.getElementById("js-notification") !== null) {
//   // Check if Cookie is already set
//   checkNotificationOnLoad();

//   // Click event
//   document.getElementById('js-notification-close').addEventListener('click', function () {
//     setCookie('notification','1','15');
//     document.getElementById("js-notification").classList.add('notification--hidden');
//   });
// }

// Set Cookie
// function setCookie(cname, cvalue, exdays) {
//   var d = new Date();
//   d.setTime(d.getTime() + (exdays*24*60*60*1000));
//   var expires = "expires="+d.toUTCString();
//   document.cookie = cname + "=" + cvalue + "; " + expires;
// }

// // Get Cookie
// function getCookie(cname) {
//   var name = cname + "=";
//   var ca = document.cookie.split(';');
//   for(var i=0; i<ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0)==' ') c = c.substring(1);
//     if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
//   }
//   return "";
// }





// // Check Cookie
// function checkNotificationOnLoad() {
//   if(getCookie("notification") !== "1") {
//     if (document.getElementById("js-notification")) {
//       document.getElementById("js-notification").style.display = "block";
//     }
//   } else {
//     if (document.getElementById("js-notification")) {
//       document.getElementById("js-notification").style.display = "none";
//     }
//   }
// }