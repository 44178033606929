const searchInput   = document.querySelector('.js-search-bar__input');
const searchSubmit  = document.querySelector('.js-search-bar__button');
const searchForm    = document.querySelector('.js-search-bar__form');
const searchClose   = document.querySelector('.js-search-bar__close');
const searchWrapper = document.querySelector(".js-search-bar__wrapper");

const headerSearchContainer = document.querySelector('.search-bar-nav');
const headerSearchOpen = document.querySelector('.js-search-bar-nav-icon .icon--search-glass');
const headerSearchClose = document.querySelector('.js-search-bar-nav-icon .icon--close');

if (searchInput) {
  searchInput.addEventListener('input', function (event) {
    if (this.value.length > 0) {
      if (this.value.length > 2) {
        //throttleSubmit();
        submitForm();
      }
    } else {
      searchWrapper.classList.remove('is-open');
    }
  });

  searchClose.addEventListener('click', function (event) {
    event.preventDefault();
    searchInput.value="";
    searchWrapper.classList.remove('is-open');
  });

  function submitForm() {
    Rails.fire(searchForm, 'submit')
  }

  function throttle(func, limit) {
    var inThrottle
    return (...args) => {
      if (!inThrottle) {
        func(...args)
        inThrottle = setTimeout(() => inThrottle = false, limit)
      }
    }
  }

  //var throttleSubmit = throttle(submitForm, 300)
}

// Header Search
if (headerSearchOpen) {
  headerSearchOpen.addEventListener('click', () => {
    headerSearchContainer.classList.add('search-bar-nav--active');
  });
}

if (headerSearchClose) {
  headerSearchClose.addEventListener('click', () => {
    headerSearchContainer.classList.remove('search-bar-nav--active');
    searchInput.value = ""
    searchWrapper.classList.remove('is-open');
  });
}