// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()

import Rails from "@rails/ujs";
window.Rails = Rails;

require("@rails/activestorage").start()
require("channels")

// https://dev.to/morinoko/adding-custom-javascript-in-rails-6-1ke6
require("custom/polyfill")
require("custom/notification")
require("custom/menu")
require("custom/slider")
require("custom/accordion")
require("custom/map")
require("custom/blank")
require("custom/smartrechner")
require("custom/facts-counter")
require("custom/search")
require("custom/table-of-contents")
require("custom/consent-banner")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
