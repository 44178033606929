var statNumbers = document.querySelectorAll(".stats__number");

var animationDuration = 2000;
var frameDuration = 1000 / 60;
var totalFrames = Math.round(animationDuration / frameDuration);


function easeOut(t) {
  return t * (2 - t);
}

function countUp(element) {
  var frame = 0;
  var countTo = parseInt(element.dataset.value, 10);

  var counter = setInterval(function(){
    frame++;

    var progress = easeOut(frame / totalFrames);
    var currentCount = Math.round(countTo * progress);

    if (parseInt(element.innerHTML, 10) !== currentCount) {
      element.innerHTML = currentCount;
    }

    if (frame === totalFrames) {
      clearInterval(counter);
    }
  }, frameDuration);
}

statNumbers.forEach(function (statNumber) {
  countUp(statNumber);
})