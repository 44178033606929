const menu = document.getElementById('js-main-menu');
const menuTrigger = document.getElementById('js-main-menu__mobile-trigger');

if (menuTrigger !== null && menu !== null) {
  menuTrigger.addEventListener('click', function(){
    menu.classList.toggle('is-active');
  })
}

const menuLinks = document.querySelectorAll('.main-menu__item.has-children > .main-menu__link')
const menuItems = document.querySelectorAll('.main-menu__item');

if (menuLinks !== null) {
  menuLinks.forEach(link => {
    link.addEventListener('click', event => {
      event.preventDefault();
      event.stopPropagation();
      let menuItem = link.closest('.main-menu__item');

      if (menuItem.classList.contains('is-open')) {
        menuItem.classList.remove('is-open');
      }
      else {
        let menuLevel = menuItem.closest('ul');
        if (menuLevel.classList.contains('main-menu__level--1')) {
          menuItems.forEach(menuItem => {
            menuItem.classList.remove('is-open');
          })
        }
        menuItem.classList.add('is-open');
      }
    })
  })
}

// close menu by clicking anywhere outside
document.body.addEventListener('click', event => {
  menuItems.forEach(menuItem => {
    menuItem.classList.remove('is-open');
  })
})
