"use strict"

import { map } from "../map/index"
import { setCookie, getCookie, eraseCookie } from "../cookies/index"

export default class ContentShield {
  constructor() {
    this.consentItems = document.querySelectorAll(".content-shield")
  }

  init() {
    this.initButtons();
    this.activateItems();
  }

  initButtons = () => {
    const consentItemsArray = Array.from(this.consentItems)

    this.consentItems.forEach(item => {
      const consentButton = item.querySelector(".content-shield__overlay .button")
      const revokeConsentButton = item.querySelector(".js-revoke-consent")
      const identifier = item.dataset.serviceIdentifier;

      if (!getCookie(identifier) && consentButton) {
        consentButton.addEventListener("click", () => {
          setCookie(identifier, "agreed", 365)
          const filteredConsentItems = consentItemsArray.filter(wrapper => wrapper.dataset.serviceIdentifier === identifier)

          filteredConsentItems.forEach(item => {
            this.activateItem(item);
          })
        })
      }

      if (revokeConsentButton) {
        revokeConsentButton.addEventListener('click', (e) => {
          e.preventDefault();
          eraseCookie(identifier);
          window.location.reload();
        })
      }
    })
  }

  activateItems = () => {
    this.consentItems.forEach(item => {
      if (getCookie(item.dataset.serviceIdentifier) || item.hasAttribute('data-auto-activate')) {
        this.activateItem(item);
      }
    })
  }

  activateItem = (item) => {
    if (!item.classList.contains('content-shield--inactive')) {
      item.classList.add('content-shield--inactive');

      const identifier = item.dataset.serviceIdentifier;
      const targetContainer = item.dataset.targetContainer;

      if (identifier === "diroforms") {
        this.activateIFrame(item);
      } else if (identifier === "openstreetmap") {
        this.activateMap(item);
      } else if (identifier === "head" || targetContainer === "head") {
        this.activateHead(item);
      } else {
        this.activateMisc(item);
      }
    }
  }

  // iFrame
  activateIFrame = (item) => {
    const iframe = item.querySelector('iframe');
    if (iframe) iframe.src = iframe.dataset.src
  }

  // Map
  activateMap = (item) => {
    const mapContainer = item.querySelector('.js-map');
    map(mapContainer);
  }

  // Misc (smartrechner, custom consent, calenso, youtube, vimeo, analytics, …)
  activateMisc = (item) => {
    const content = item.querySelector('template').content;
    const container = item.querySelector('.content-shield__main');
    container.appendChild(content);
  }

  // Code inside of <head></head>
  activateHead = (item) => {
    const content = item.querySelector('template').content;
    document.head.appendChild(content);
  }
}

