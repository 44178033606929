const calculators = document.querySelectorAll('.js-smartrechner');

if (calculators.length > 0) {
  calculators.forEach(function(element) {
    element.classList.add('smartrechner');
    element.addEventListener("click", (e) => {
      window.setTimeout(function() {
        const calculator = element.querySelector('.ui-mywidget')
        const calculatorHeight = calculator.getBoundingClientRect().height;

        const result = element.querySelector('.ui-myresult')
        const resultHeight = result.getBoundingClientRect().height;

        const diff = resultHeight - calculatorHeight + 40

        if (diff > 0) {
          element.setAttribute("style","padding-bottom:" + diff + "px");
        }
      }, 2000);
    })
  })
}