var L = require('leaflet');
var LC = require('leaflet.markercluster');

export const map = (mapContainer) => {
    if (mapContainer !== null) {

      // get values
      var positionLong = mapContainer.getAttribute('data-long');
      var positionLat  = mapContainer.getAttribute('data-lat');
      var zoomLevel    = mapContainer.getAttribute('data-zoom');
      var iconUrl      = mapContainer.getAttribute('data-icon');
      var zoomSnap     = 0.25

      // Disable Controls
      var zoomControl  = mapContainer.classList.contains('map--no-controls');
      if (zoomControl == true) {
        var mapControls = false;
      } else {
        var mapControls = true;
        showFirstOverlay();
      }

      // initialize Leaflet
      var map = L.map(mapContainer, { zoomSnap: zoomSnap, zoomControl: mapControls, scrollWheelZoom: false }).setView({lon: positionLong + 2, lat: positionLat}, zoomLevel);
      // add the OpenStreetMap tiles
      var tiles = L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
      }).addTo(map);
      // show the scale bar on the lower left corner
      L.control.scale().addTo(map);

      // Marker Settings
       var myIcon = L.icon({
        iconUrl: iconUrl, // pull out values as desired from the feature feature.properties.style.externalGraphic.
        iconSize: [40, 40],
        iconAnchor: [25, 49],
        popupAnchor: [0, -50],
        shadowUrl: '',
        shadowSize: [68, 95],
        shadowAnchor: [35, 80]
      });

      var markers = L.markerClusterGroup({
        maxClusterRadius: 56,
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: true,
        zoomToBoundsOnClick: false,
        removeOutsideVisibleBounds: true,
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: { opacity: 0 },
      });

      // show a marker on the map
      // L.marker({lon: markerLong, lat: markerLat}, {icon: myIcon}).bindPopup(markerText).addTo(map);

      // show Submarkers on the Map
      var allSubmarkers = mapContainer.querySelectorAll('.map__submarker');

      // Reverse Items (if leaflet is rendering items in wrong order)
      // var submarkesArray = [ ...allSubmarkers ];
      // var submarkers     = submarkesArray.reverse();
      var submarkers = allSubmarkers;

      if (submarkers.length) {
        for (var i = submarkers.length - 1; i >= 0; i--) {
          var submarkerLong   = submarkers[i].getAttribute('data-submarkerLong');
          var submarkerLat    = submarkers[i].getAttribute('data-submarkerLat');
          var submarkerText   = submarkers[i].getAttribute('data-submarkerText');
          var submarkerCity   = submarkers[i].getAttribute('data-submarkerCity');
          var submarkerName   = submarkers[i].getAttribute('data-submarkerName');
          var marker = L.marker({lon: submarkerLong, lat: submarkerLat},{icon: myIcon, alt: i, title: submarkerCity + " | " + submarkerName}) // .bindPopup(submarkerText).addTo(map);
          marker.bindPopup(submarkerText);
          // if not diro map: Marker Behaviour updates Overlay
          marker.on('click', function(e) {
            let pixel = map.project(e.target._popup._latlng);
            pixel.y -= e.target._popup._contentNode.clientHeight/2;
            map.panTo(map.unproject(pixel), {animate: true});
            var markerId = e.target.options.alt;
            updateOverlay(markerId);
          });
          markers.addLayer(marker);
        }
      }

      map.addLayer(markers)

      markers.on('clusterclick', function (e) {
        let markerAll = mapContainer.querySelectorAll("[class*='marker-cluster-']");
        markerAll.forEach((item) => {
          if (item.style.opacity === '0.3') {
            item.style['opacity'] = 0;  // set the opacity to 0 at last layer
          }
        });
        let curZoom = map.getZoom();
        let clusterPos = e.layer.getLatLng();
        if (e.layer._childClusters.length == 1 && e.layer._zoom < 10) {
          map.setView(clusterPos, curZoom + 3);
        } else if (e.layer._childClusters.length > 1) {
          map.setView(clusterPos, curZoom + 3);
        } else {
          e.layer.zoomToBounds();
        }
      });

      // Update Overlay Content
      function updateOverlay(markerID) {
        var overlayItems = document.querySelectorAll('.map__overlay-item');
        var currentOverlayContent = document.querySelector('.map__overlay-item--' + markerID);

        overlayItems.forEach(function(overlayItem, key) {
          overlayItem.classList.remove('is-active');
        });

        currentOverlayContent.classList.add('is-active');
      }

      // Show initial first overlay
      function showFirstOverlay() {
        // var firstOverlayItem = document.querySelector('.map__overlay-item--0')
        var firstOverlayItem = document.querySelector('.map__overlay-item')
        if (firstOverlayItem) {
          firstOverlayItem.classList.add('is-active');
        }
      }

      // var controlsTest = document.querySelectorAll('.leaflet-control-zoom-in');
      // // DISPLAY AREA MARKERS
      // map.on('zoom', function() {
      //   // displayAreaMarker();
      // });
    }
}
