const COOKIEPREFIX = 'DIRO_C_';

const setCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = COOKIEPREFIX + name + '=' + (value || '') + expires + '; path=/'
}

const getCookie = (name) => {
  let nameEQ = COOKIEPREFIX + name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

const eraseCookie = (name, addPrefix=true) => {
  const prefix = addPrefix ? COOKIEPREFIX : ''
  document.cookie = prefix + name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

const eraseAllCookies = () => {
  const cookies = document.cookie.split(';')
  cookies.forEach(cookie => {
    let cookieName = cookie.split('=')[0];
    while (cookieName.charAt(0) == ' ') cookieName = cookieName.substring(1, cookieName.length);
    if (cookieName.startsWith(COOKIEPREFIX)) eraseCookie(cookieName, false);
  })
}

export { setCookie, getCookie, eraseCookie, eraseAllCookies }
