const externalLinks = document.querySelectorAll('a[href^="http"]');
const exceptions = document.querySelectorAll('.header');
const filteredLinks = [...externalLinks].filter((link) => {
  let isException = false;
  exceptions.forEach(exception => {
    if (exception.contains(link)) isException = true;
  })
  return !isException;
})

filteredLinks.forEach(function(el) {
  let linkURL;

  try {
    linkURL = new URL(el.getAttribute('href'));
  } catch (e) {
    return;
  }

  const isInternalLink = window.location.hostname === linkURL.host;

  if (!isInternalLink) {
    el.setAttribute('target', '_blank');
    el.setAttribute('rel', 'noopener noreferrer');
  }
});
