// Element.closest()
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}



// NodeList.forEach()
// https://gist.github.com/bob-lee/e7520bfcdac266e5490f40c2759cc955

if ('NodeList' in window && !NodeList.prototype.forEach) {
  // console.info('polyfill for IE11');
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}