function accordionInit() {
  const accordionItems = document.querySelectorAll('.js-accordion__item');

  if (accordionItems.length == 0) {
    return;
  }

  accordionItems.forEach(function(accordionItem){
    const accordionTrigger = accordionItem.firstElementChild;
    accordionTrigger.addEventListener("click", function(event) {
      event.preventDefault();
      accordionItem.classList.toggle("is-active");
    });
  });
}

accordionInit();