"use strict"

import ContentShield from "../content-shield/index"
import { setCookie, getCookie, eraseAllCookies } from "./../cookies/index"

export default class ConsentBanner {
  constructor() {
    this.consentBanner = document.querySelector('#js-consent-banner')

    if (this.consentBanner) {

      this.consentTypeSwitches    = document.querySelectorAll('.js-consent-type-switch input');
      this.consentSwitches        = document.querySelectorAll('.js-consent-switch input');
      this.settingsButton         = document.querySelector(".js-consent-open-settings");
      this.saveSettingsButton     = document.querySelector(".js-consent-save-settings");
      this.exitSettingsButton     = document.querySelector(".js-consent-exit-settings");
      this.acceptButton           = document.querySelector('.js-consent-accept-all');
      this.rejectButton           = document.querySelector('.js-consent-reject-all');
      this.reviseSettingsButtons  = document.querySelectorAll('.js-consent-revise-settings');
      this.currentFingerprint     = document.querySelector('body').dataset.consentFingerprint;
      this.prevFingerprint        = getCookie('FINGERPRINT');
      this.contentShield          = new ContentShield();

      if (this.currentFingerprint === 'disabled') {

        // Only mandatory and/or inactive consents
        this.consentBanner.classList.add('is-hidden');
        eraseAllCookies();

      } else {

        // At least one activated consent
        if (this.prevFingerprint === this.currentFingerprint) {

          // Backend settings unchanged
          this.consentBanner.classList.add('is-hidden');

        } else {

          // Backend settings changed since last visit
          eraseAllCookies();
        }

        this.initButtons();
        this.initAccordion();
        this.initSwitches();
      }

      this.contentShield.init();
    }
  }

  initButtons = () => {
    const settings = document.querySelector(".consent-banner__settings-wrapper")
    const consentIntro = document.querySelector(".consent-banner__intro")

    if (this.settingsButton && settings && consentIntro) {
      this.settingsButton.addEventListener("click", () => {
        consentIntro.classList.remove("consent-banner__intro--active");
        settings.classList.add("consent-banner__settings-wrapper--active");
      })
    }

    if (this.saveSettingsButton) {
      this.saveSettingsButton.addEventListener('click', () => {
        const activeSwitches = Array.from(this.consentSwitches).filter(consentSwitch => consentSwitch.checked);
        this.processUserChoices(activeSwitches);
      })
    }

    if (this.exitSettingsButton) {
      this.exitSettingsButton.addEventListener('click', () => {
        consentIntro.classList.add("consent-banner__intro--active");
        settings.classList.remove("consent-banner__settings-wrapper--active");
      })
    }

    if (this.acceptButton) {
      this.acceptButton.addEventListener('click', () => {
        this.processUserChoices(this.consentSwitches);
      });
    }

    if (this.rejectButton) {
      this.rejectButton.addEventListener('click', () => {
        const mandatoryConsentSwitches = Array.from(this.consentSwitches).filter(consentSwitch => consentSwitch.hasAttribute('data-mandatory'));
        this.processUserChoices(mandatoryConsentSwitches);
      });
    }

    this.reviseSettingsButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        this.consentBanner.classList.remove('is-hidden');
        this.consentBanner.classList.remove('is-closed');
        this.updateSwitchStates();
        consentIntro.classList.remove("consent-banner__intro--active");
        settings.classList.add("consent-banner__settings-wrapper--active");
      })
    })
  }

  initAccordion = () => {
    const accordionHeader = document.querySelectorAll(".consent-banner__accordion-item .consent-banner__accordion-header-inner")

    if (accordionHeader.length) {
      accordionHeader.forEach(accordion => {
        accordion.addEventListener("click", () => {
          accordion.parentElement.parentElement.classList.toggle("consent-banner__accordion-item--active");
        });
      })
    }
  }

  initSwitches() {
    this.consentTypeSwitches.forEach(typeSwitch => {
      if (!typeSwitch.hasAttribute('data-mandatory')) {
        typeSwitch.addEventListener('change', () => {
          this.consentSwitches.forEach(consentSwitch => {
            if (consentSwitch.dataset.typeIdentifier == typeSwitch.dataset.typeIdentifier) {
              consentSwitch.checked = typeSwitch.checked;
            }
          })
        })
      }
    })

    this.consentSwitches.forEach(consentSwitch => {
      if (!consentSwitch.hasAttribute('data-mandatory')) {
        consentSwitch.checked = !!getCookie(consentSwitch.dataset.identifier);

        consentSwitch.addEventListener('change', () => {
          const parentSwitch = this.getTypeSwitchFor(consentSwitch.dataset.typeIdentifier);

          if (!consentSwitch.checked) {
            parentSwitch.checked = false;
          } else {
            let allConsentsOfType = this.allConsentsOfType(consentSwitch.dataset.typeIdentifier)
            if (this.allChecked(allConsentsOfType)) parentSwitch.checked = true;
          }
        })
      }
    })
  }

  getTypeSwitchFor(typeIdentifier) {
    return this.consentBanner.querySelector(`.js-consent-type-switch[data-type-identifier=${typeIdentifier}] input`);
  }

  updateSwitchStates() {
    this.consentSwitches.forEach(consentSwitch => {
      if (!consentSwitch.hasAttribute('data-mandatory')) {
        consentSwitch.checked = !!getCookie(consentSwitch.dataset.identifier);
      }
    });

    this.consentTypeSwitches.forEach(typeSwitch => {
      if (!typeSwitch.hasAttribute('data-mandatory')) {
        let allConsentsOfType = this.allConsentsOfType(typeSwitch.dataset.typeIdentifier)
        typeSwitch.checked = this.allChecked(allConsentsOfType);
      }
    })
  }

  processUserChoices(switches) {
    eraseAllCookies();
    this.acceptConsents(switches);
    setCookie('FINGERPRINT', this.currentFingerprint, 365);
    this.contentShield.activateItems();
    this.consentBanner.classList.add('is-closed');
  }

  allConsentsOfType(typeIdentifier) {
    return Array.from(this.consentSwitches).filter(filteredSwitch => {
      return filteredSwitch.dataset.typeIdentifier === typeIdentifier
    });
  }

  acceptConsents(consentSwitches) {
    consentSwitches.forEach(consentSwitch => {
      setCookie(consentSwitch.dataset.identifier, "agreed", 365);
    });
  }

  allChecked = (toggles) => {
    let check = toggles.every(item => item.checked)
    return check
  }

  arrayRemove = (arr, value) => {
    return arr.filter((ele) => {
      return ele != value
    })
  }
}

// Init
const banner = new ConsentBanner();

