// https://github.com/ganlanyuan/tiny-slider
import { tns } from "tiny-slider/src/tiny-slider"

const tinySliders = document.querySelectorAll('.slider__slides');

if (tinySliders.length) {

  tinySliders.forEach(slider => {
    let options = {
      container: slider,
      controlsText: ['<span>Prev</span>','<span>Prev</span>'],
      navPosition: 'bottom',
      mode: 'gallery',
      autoHeight: true
    };

    // @see hero slider
    if (slider.dataset.autoplay == 1) {
      options.autoplay = true;
    }
    if (slider.dataset.mode == 'gallery') {
      options.mode = 'gallery';
      options.animateDelay = 1000; // ?
      options.animateIn = 'fadeInDown';
      options.animateOut = 'fadeOutDown';
      options.autoplayButton = false;
      options.autoplayButtonOutput = false;
    }

    const tinySlider = tns(options);
  });
}
