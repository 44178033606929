let toc = document.querySelector(".toc");

if (toc) {

  const container = toc.parentElement;
  const sections = container.querySelectorAll(".section[id]");

  let anchors = {};
  let toc__headline = "";
  let toc__content = "";
  let defaultOpen = "";
  let icon = "";

  console.log(sections)

  sections.forEach((ele) => {
    let lstInner = document.createElement("ul");
    let lstInnerInner = document.createElement("ul");
    let lstItem = document.createElement("li");
    let anchor = document.createElement("a");
    let id = ele.id;

    if (anchors.hasOwnProperty(id)) {
      while (anchors.hasOwnProperty(id)) {
        id += Math.floor(Math.random() * 10);
      }
      container.querySelectorAll(`[id=${ele.id}]`)[1].setAttribute("id", id);
    }

    if (ele.querySelector("h2") != null) {
      anchor.setAttribute("href", "#" + id);
      anchor.innerText = ele.querySelector("h2").innerText;
      lstItem.appendChild(anchor);
      anchors[id] = lstItem;
    } else if (ele.querySelector("h3") != null) {
      anchor.setAttribute("href", "#" + id);
      anchor.innerText = ele.querySelector("h3").innerText;
      lstItem.appendChild(anchor);
      lstInner.appendChild(lstItem);
      anchors[id] = lstInner;
    } else if (ele.querySelector("h4") != null) {
      anchor.setAttribute("href", "#" + id);
      anchor.innerText = ele.querySelector("h4").innerText;
      lstItem.appendChild(anchor);
      lstInnerInner.appendChild(lstItem);
      lstInner.appendChild(lstInnerInner);
      anchors[id] = lstInner;
    }
  });

  let lstOuter = document.createElement("ul");

  for (let [key, value] of Object.entries(anchors)) {
    document.getElementById(key).classList.add("toc__content__target");
    lstOuter.appendChild(value);
  }

  try {
    toc__headline = document.querySelector(".toc__headline");
    toc__content = document.querySelector(".toc__content");
    toc__content.appendChild(lstOuter);
    icon = document.querySelector(".file__icon.toc__icon use")
  } catch (error) {}

  if (toc !== null) {
    defaultOpen = toc.getAttribute("toc-default") === "open" ? true : false;
    if (defaultOpen) {
      toc.classList.add("active");
      toc__content.style.maxHeight = toc__content.scrollHeight + "px";
    }
    toc__headline.addEventListener("click", function () {
      toc.classList.toggle("active");
      if (toc__content.style.maxHeight) {
        toc__content.style.maxHeight = null;
        icon.setAttribute("xlink:href", "#arrow-down")
      } else {
        toc__content.style.maxHeight = toc__content.scrollHeight + "px";
        icon.setAttribute("xlink:href", "#arrow-up")
      }
    });
  }

}
